<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术通知单"/>
        <div class="content">
            <div class="page" id="printMe">
                <div class="caption">
                    <h2>术前注意事项 </h2>
                </div>
                <p>尊敬的患者： </p>
                <p style="text-indent: 40px">
                    手术正式预约登记地点位于门诊5层皮肤科图像采集室（滚梯斜对面），在那里首先完成手术时间预约，其次完成图像采集和术前评估及知情同意签署。手术当日，请您按照约定时间前往皮肤科手术室，手术报到地点位于：
                    <b>北京大学第一医院急诊楼3层北侧西面皮肤科综合治疗区内（从门诊进入，上3层，穿过连廊至急诊楼即可抵达）</b>。
                </p>
                <p>1. 请确认您的联系电话准确，并保持联络畅通，并关注83573103来电，以便临时调整手术时我们能及时通知您。</p>
                <p>2. 如您遇特殊情况需取消/更改手术日期，请提前至少3日拨打电话83573103联系确认（工作日10-12点最佳）。</p>
                <p>3. 如您正在服用抗凝或抗血小板药物（阿司匹林、华法令、银杏叶制剂等），暂时无需停药，但务必手术时告知医生，并于手术前完成凝血功能筛查。</p>
                <p>4. 请术前戒烟戒酒至少2周。</p>
                <p>5. 如果您体内有起搏器等植入性或半植入性电子器件，请务必于术前告知医生。</p>
                <p>6. 完成图像采集及信息录入后（地点位于门诊5层滚梯斜对面皮肤科图像采集室），请您于手术当日携带以下资料：
                    <b>①医保卡/北大医院就诊卡（或医院公众号中的电子就诊卡），②术前检查结果及3日内北京地区医院所做的新冠病毒核酸检测报告，③手术知情同意书及病理申请单</b>。</p>
                <p>7. 手术当日请正常进餐（以免空腹手术发生低血糖）；高血压、糖尿病等慢病患者请按时用药控制好病情，保证手术顺利进行。</p>
                <p>8. 术前一天洗澡；手术当日请穿开身宽松衣物；如果是足部手术，请自备宽松拖鞋。女性会阴区手术需避开经期。</p>
                <h4 style="margin-top:10px">住院手术患者须知：</h4>
                <p>9. 请关注上述常规手术注意事项。</p>
                <p>10. 住院当日需出具3日内<b>北京大学第一医院</b>所做新冠病毒核酸检测报告。</p>
                <p>11. 请按照医生或护士的指示按时住院；住院前夕请并保持电话联络畅通，以备病房医生因特殊环节联系您。</p>
                <p>12. 办理住院时请携带所有病历及就诊资料，以及身份证、医保卡等证件，首先到第一住院部（西安门大街1号）皮肤科病房领取住院条，然后前往主楼1层住院处办理住院手续。</p>
                <p>13. 手术当日请在家属陪同下于8：00前抵达皮肤科手术室（从门诊进，经3层长廊抵达急诊3层皮肤科综合治疗区）进行手术。Mohs手术可能持续1整天时间，请做好午餐和饮用水准备。</p>
            </div>

            <div class="btn">
                <el-button type="primary" v-print="'#printMe'">打印通知单</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: '',
                date: '',
                time: ''
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/surgery/appoint/${this.$route.query.id}`)
                this.name = resp.data.data.name
                this.date = resp.data.data.date
                this.time = resp.data.data.time
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .content {
        max-width: 800px;
        margin: 30px auto;

        .btn {
            text-align: center;
            margin-top: 30px;
        }
    }

    .page {
        max-width: 800px;
        font-size: 20px;
        line-height: 32px;

        h2, h4 {
            margin: 0;
            padding: 0;
        }

        p, ul {
            margin: 0;
        }

        ul {
            margin-left: 0;
            padding-left: 20px;
        }

        li {
            margin-left: 0;
            padding-left: 0;
        }

        div.caption, div.map {
            text-align: center;
            padding:20px;
        }

        div.map img {
            max-width: 400px;
        }
    }
</style>
